/** ***************************************************************************
 * App / Molecules / Field
 * FIXME: this is an exact replica of @app-molecules-forms-field
 *************************************************************************** */

class LemuriaPasswordField {
  constructor(element) {
    this.element = element;
    this.inputElement = element.querySelector('input');
    this.eyeIcon = element.querySelector('.eye-icon');

    this.inputElement.addEventListener('input', (e) => {
      if (this.inputElement.value) {
        this.eyeIcon.style.display = 'block';
      } else {
        this.eyeIcon.style.display = 'none';
      }
    });

    this.eyeIcon.addEventListener('click', (e) => {
      // toggle the type attribute
      const type =
        this.inputElement.getAttribute('type') === 'password'
          ? 'text'
          : 'password';
      this.inputElement.setAttribute('type', type);
      // toggle the eye / eye slash icon
      this.eyeIcon.classList.toggle('active');
    });
  }
}

window.addEventListener('lemuria:init', () => {
  const selector = '.lm-c-field input[type="password"]';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      const container = element.closest('.lm-c-field');
      element.lmPasswordField = new LemuriaPasswordField(container);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      const container = element.closest('.lm-c-field');
      if (container.lmPasswordField) {
        container.lmPasswordField.destroy();
        container.lmPasswordField = undefined;
      }
    });
  }

  window.LEMURIA.register(selector, initialize, destroy);

  initialize();
});

export { LemuriaPasswordField };

/** ***************************************************************************
 * Molecules / PB / Tutorial
 *************************************************************************** */

import PbModalComponent from '../../../01-common/03-organisms/modal/modal';

class TutorialComponent {
  constructor(element) {
    this.element = element;
    this.videoSrc = this.element.getAttribute('data-video');
    this.poster = this.element.querySelector('.lm-c-video_poster');
    this.videoComponent = document.querySelector('.lm-c-video');
    this.pbModalTemplate = document.getElementById('lm-c-pb-modal-template');
    this.body = document.querySelector('body');

    this.setUpEventListeners();

    this.initialize();
  }

  initialize() {
    const pbModalFragment = this.buildPbModal();
    this.body.appendChild(pbModalFragment);
    const pbModal = this.body.lastElementChild;
    this.pbModalComponent = new PbModalComponent(pbModal, 'tutorial');
  }

  buildPbModal() {
    return this.pbModalTemplate.content.cloneNode(true);
  }

  open() {
    this.pbModalComponent.open(this.videoSrc);
  }

  setUpEventListeners() {
    this.poster.addEventListener('click', this.open.bind(this));
  }
}

window.addEventListener('lemuria:init', () => {
  const selector = '.lm-c-tutorial';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.lmTutorial = new TutorialComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.lmTutorial) {
        element.lmTutorial.destroy();
        element.lmTutorial = undefined;
      }
    });
  }

  window.LEMURIA.register(selector, initialize, destroy);

  initialize();
});

export { TutorialComponent as default };

/** ***************************************************************************
 * Molecules / Slider / Slider
 *************************************************************************** */

import Splide from '@splidejs/splide';

class SliderComponent {
  constructor(element) {
    this.element = element;
    this.defaultOptions = {
      type: 'slide',
      pagination: false,
      gap: 'var(--splide-items-gap)',
      autoWidth: true,
      autoScroll: {
        autostart: false,
        speed: 1,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
      // perPage: 1,
      // drag: 'free',
      // snap: false,
      // flickMaxPages: 1,
      // autoplay: false,
      // autoHeight: true,
      // trimSpace: 'move',
      // fixedHeight: '40rem',
      // breakpoints: {
      //   1080: {
      //     fixedHeight: '13.125rem', // 210px
      //     // autoWidth: false,
      //     // perPage: 1,
      //   },
      //   1280: {
      //     fixedHeight: '22.5rem',
      //   },
      //   1440: {
      //     fixedHeight: '26.75rem',
      //   },
      //   1700: {
      //     fixedHeight: '30.125rem',
      //   },
      //   1920: {
      //     fixedHeight: '35rem',
      //   },
      // },
      // classes: {
      //   // Add classes for arrows.
      //   arrows: 'splide__arrows your-class-arrows',
      //   arrow : 'splide__arrow your-class-arrow',
      //   prev  : 'splide__arrow--prev your-class-prev',
      //   next  : 'splide__arrow--next your-class-next',

      //   // Add classes for pagination.
      //   pagination: 'splide__pagination your-class-pagination', // container
      //   page      : 'splide__pagination__page your-class-page', // each button
      // },
    };
  }

  initialize(options, extensions) {
    this.splide = new Splide(this.element, {
      ...this.defaultOptions,
      ...options,
    });

    this.splide.mount({ ...extensions });
  }

  destroy() {
    this.splide.destroy();
  }
}

window.addEventListener('lemuria:init', () => {
  const selector = '.splide';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.lmSlider = new SliderComponent(element);
      element.dispatchEvent(new Event('sliderComponent:init'));
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.lmSlider) {
        element.lmSlider.destroy();
        element.lmSlider = undefined;
      }
    });
  }

  window.LEMURIA.register(selector, initialize, destroy);

  initialize();
});

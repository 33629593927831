/** ***************************************************************************
 * Website / Organisms / Pre-Footer
 *************************************************************************** */

class PrefooterComponent {
  constructor(element) {
    this.element = element;
    this.form = element.querySelector('form');
    this.button = element.querySelector('button');

    console.log(this.button);

    this.form.onsubmit = this.onsubmit;
  }

  destroy() {
    this.form.onsubmit = undefined;
  }

  hideArrow() {
    this.button.querySelector('svg').style.display = 'none';
  }

  showArrow() {
    this.button.querySelector('svg').style.removeProperty('display');
  }

  setLabel(label) {
    this.button.querySelector('span').textContent = label;
  }

  onsubmit = (event) => {
    event.preventDefault();

    const buttonLabel = this.button.querySelector('span').textContent;
    const form = event.target;
    const data = new FormData(form);
    const csrf = form.querySelector('[name="csrfmiddlewaretoken"]').value;

    this.button.setAttribute('disabled', '');
    this.button.style.opacity = '0';

    fetch(form.action, {
      method: form.method,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRFToken': csrf,
      },
      body: new URLSearchParams(data),
    }).then((response) => {
      event.target.querySelectorAll('input, textarea').forEach((field) => {
        field.value = '';
      });
      setTimeout(() => {
        this.button.style.opacity = '0';
        this.button.addEventListener('transitionend', () => {
          this.showArrow();
          this.setLabel(buttonLabel);
          this.button.style.removeProperty('opacity');
        }, {once: true})
        this.button.removeAttribute('disabled');
      }, 3000);
      this.button.style.removeProperty('opacity');
      this.setLabel(gettext('Thanks'));
    }).catch((error) => {
      console.log(error);
      this.showArrow();
      this.button.style.removeProperty('opacity');
      this.setLabel(buttonLabel);
      this.button.removeAttribute('disabled');
    });

  }
}


window.addEventListener('lemuria:init', () => {
  const selector = '.lm-c-pre_footer';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.lmPrefooter = new PrefooterComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.lmPrefooter) {
        element.lmPrefooter.destroy();
        element.lmPrefooter = undefined;
      }
    });
  }

  window.LEMURIA.register(selector, initialize, destroy);

  initialize();
});

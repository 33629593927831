/** ***************************************************************************
 * Website / Organisms / Header
 *************************************************************************** */

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade';

class HeaderComponent {
  constructor(element) {
    this.element = element;
    this.toggle = document.getElementById('lm-c-header__toggle');
    this.buttons = document.querySelectorAll('.lm-c-navigation li a');
    this.setupEventListeners();

    this.toggle.onchange = this.onchange;
    this.buttons.forEach((button) => (button.onclick = this.onclick));
    window.addEventListener('resize', this.onresize); // FIXME: use a resize observer
  }

  destroy() {
    window.removeEventListener(this.onresize);
    this.toggle.onchange = undefined;
  }

  setupEventListeners() {
    this.onresize = () => {
      if (window.matchMedia('(min-width: 50rem)').matches) {
        this.toggle.checked = false;
        this.toggle.onchange = undefined;
        enableBodyScroll(this.element);
      } else {
        this.toggle.onchange = this.onchange;
      }
    };
    this.onchange = (event) => {
      if (event.target.checked) {
        disableBodyScroll(this.element, { reserveScrollBarGap: true });
        window.addEventListener;
      } else {
        enableBodyScroll(this.element);
      }
    };
    this.onclick = (event) => {
      this.toggle.click();
      enableBodyScroll(this.element);
    };
  }
}

window.addEventListener('lemuria:init', () => {
  const selector = '.lm-c-header';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.lmHeaderComponent = new HeaderComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.lmHeaderComponent) {
        element.lmHeaderComponent.destroy();
      }
    });
  }

  window.LEMURIA.register(selector, initialize, destroy);

  initialize();
});

export { HeaderComponent };

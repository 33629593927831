/** ***************************************************************************
 * Website / Organisms / Partners Slider
 *************************************************************************** */

import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { Intersection } from '@splidejs/splide-extension-intersection';

class PartnersSliderComponent {
  constructor(element) {
    this.element = element;
    this.slider = this.element.querySelector('.splide');
    this.slider.addEventListener('sliderComponent:init', () => {
      this.initializeSlider();
    });
  }

  initializeSlider() {
    this.options = {
      type: 'loop',
      arrows: false,
      pagination: false,
      drag: 'free',
      gap: 'var(--splide-items-gap)',
      intersection: {
        inView: {
          autoScroll: true,
        },
        outView: {
          autoScroll: false,
        },
      },
    };
    this.extensions = { AutoScroll, Intersection };
    this.slider.lmSlider.initialize(this.options, this.extensions);
  }
}

window.addEventListener('lemuria:init', () => {
  const selector = '.lm-c-partners_slider';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.lmPartnersSlider = new PartnersSliderComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.lmPartnersSlider) {
        element.lmPartnersSlider.destroy();
        element.lmPartnersSlider = undefined;
      }
    });
  }

  window.LEMURIA.register(selector, initialize, destroy);

  initialize();
});

/** ***************************************************************************
 * Website / Organisms / Quotes Slider
 *************************************************************************** */

class QuotesSliderComponent {
  constructor(element) {
    this.element = element;
    this.slider = this.element.querySelector('.splide');
    this.slider.addEventListener('sliderComponent:init', () => {
      this.initializeSlider();
    });
  }

  initializeSlider() {
    this.options = {
      type: 'loop',
      arrows: true,
      pagination: true,
      autoWidth: false,
      gap: 'var(--splide-items-gap)',
      classes: {
        page: 'splide__pagination__page default-cursor',
      },
    };
    this.slider.lmSlider.initialize(this.options);
  }
}

window.addEventListener('lemuria:init', () => {
  const selector = '.lm-c-quotes_slider';

  function initialize() {
    document.querySelectorAll(selector).forEach((element) => {
      element.lmQuotesSlider = new QuotesSliderComponent(element);
    });
  }

  function destroy() {
    document.querySelectorAll(selector).forEach((element) => {
      if (element.lmQuotesSlider) {
        element.lmQuotesSlider.destroy();
        element.lmQuotesSlider = undefined;
      }
    });
  }

  window.LEMURIA.register(selector, initialize, destroy);

  initialize();
});
